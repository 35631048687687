import * as types from './mutation-types'
import MutationHelpers from '../mutation_helpers'

const helpers = new MutationHelpers('order_shipments')

export default {
  [types.FETCH_ORDER_SHIPMENTS_SUCCESS](state, response) {
    helpers.replaceEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_RELATED_ORDER_SHIPMENTS_SUCCESS](state, response) {
    helpers.storeResourcesToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.GET_ORDER_SHIPMENT_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.ADD_ORDER_SHIPMENT_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)
    helpers.pushResourceToResult(state, response)

    state.isCallingAPI = false
  },

  [types.UPDATE_ORDER_SHIPMENT_SUCCESS](state, response) {
    helpers.storeOneResourceToEntities(state, response)

    state.isCallingAPI = false
  },

  [types.DELETE_ORDER_SHIPMENT_SUCCESS](state, id) {
    helpers.removeOneResourceFromEntities(state, id)

    state.isCallingAPI = false
  },

  [types.GET_ORDER_SHIPMENT_EXISTING_METHOD_NAMES_SUCCESS](state, response) {
    state.existingMethodNames = response.data.existing_method_names

    state.isCallingAPI = false
  },

  [types.FETCH_EXPRESS_MAP_PARAMS_SUCCESS](state, response) {
    state.expressMapParams = response.data.data
    state.expressMapApiBase = response.data.api_base

    state.isCallingAPI = false
  },

  [types.API_REQUEST_START](state, action = 'not provided') {
    state.isCallingAPI = true
  },

  [types.API_REQUEST_SUCCESS](state) {
    state.isCallingAPI = false
  },

  [types.API_REQUEST_FAIL](state, errors) {
    state.errors.record(errors)
    state.isCallingAPI = false
  }
}
