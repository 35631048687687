<template lang="pug">
b-tabs.k-tabs-kolnet-rounded-v2(
  :class="{ 'is-full': options.isFull }"
  :value="value"
  :expanded="expanded"
  @input="inputHandler"
  :size="size"
)
  slot
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  value: { type: [Number, String] },
  expanded: { type: Boolean },
  size: { type: String },
  options: {
    type: Object,
    default: () => {
      return { isFull: false }
    }
  }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'

.k-tabs-kolnet-rounded-v2
  $indicator-size: .5rem
  $border-width: 4px
  $border-color: color.$ci

  --text-color: #{color.$c-gray}
  --text-color-active: #{color.$c-white}
  $border-radius: 1.25rem
  $active-color: $border-color
  --icon-size: 44px
  --fz-5: #{font.$fz-5}

  $border-color-lighten: hsl(188deg 100% 50%)

  &.is-full
    .tabs ul
      padding: 0
    .tabs ul li
      width: 100%
      border-top-left-radius: 0
      border-top-right-radius: 0
      &:after
        border-top-left-radius: 0
        border-top-right-radius: 0
  .tabs
    &.is-small
      ul li[role='tab']
        font-size: font.$normal
  .tab-content
    display: none // default no content

  // expanded
  &.is-fullwidth
    .tabs.is-fullwidth ul
      gap: 0

  .tabs
    display: flex
    align-items: stretch
    justify-content: space-between
    overflow-x: scroll
    scrollbar-width: none
    border-bottom: $border-width solid $border-color
    ul
      display: flex
      flex-grow: 1
      flex-shrink: 0
      justify-content: center
      padding-inline: 1rem
      align-items: normal
      border-bottom: 0

    ul li
      +utils.has-transition(.3, all)
      position: relative
      z-index: 0
      padding: 0
      cursor: pointer
      border-bottom: 0
      border-top-left-radius: $border-radius
      border-top-right-radius: $border-radius
      a
        display: flex
        flex-direction: column
        flex-shrink: 0
        align-items: center
        color: var(--text-color)
        font-size: font.$fz-small

      &::after
        +utils.has-transition(.3, top)

        $background-color-start: $active-color
        $background-color-end: $border-color

        position: absolute
        inset: 0
        top: 100%
        z-index: -1
        content: ""
        background-image: linear-gradient(to top, $background-color-start, $background-color-end)
        border-top-left-radius: $border-radius
        border-top-right-radius: $border-radius
        opacity: 0

      &:hover
        &::after
          top: calc(100% - 0.75rem)
          opacity: 0.5

      &.is-active
        a
          color: var(--text-color-active)

        &::after
          top: 0
          opacity: 1

        &:hover
          &::after
            top: 0

      +utils.from(size.$ipad)
        --text-color: #{color.$c-alto}

        min-width: 12rem
        margin-inline: -0.25rem
        background-color: color.$c-white
        border: 3px solid color.$c-alto
        border-color: color.$c-concrete
        border-bottom: 0
        font-weight: font.$semibold
        a
          font-size: font.$fz-normal

        &::after
          $background-color-start: $active-color
          $background-color-end: $border-color-lighten
          background-image: linear-gradient(to top, $background-color-start, $background-color-end)

        &:hover
          z-index: 1
          border-color: color.$c-alto

          &::after
            top: 100%

        &.is-active
          z-index: 1
          border-width: 0

          > .text-wrapper
            translate: 0 calc(var(--border-bottom-width, 4px) * 1)
    ul li a
      padding: 0.5rem
      padding-bottom: 1rem
      border-bottom: none
      width: 100%

      +utils.from(size.$ipad)
        padding-bottom: 0.5rem

    ul li a span.icon
      display: grid
      place-items: center
      width: var(--icon-size)
      height: var(--icon-size) //- should not to be added
      aspect-ratio: 1
      margin-bottom: 0.5rem
      font-size: var(--fz-5)
      color: var(--text-color)
      text-align: center
      background-color: var(--c-white)
      border-radius: 1rem
      box-shadow: 0 0 0.5rem hsl(0deg 0% 0% / 25%)
      margin-right: 0

      +utils.from(+size.$ipad)
        display: none

    ul li a span:not(.icon)
      font-weight: font.$semibold
      +utils.from(+size.$ipad)
        translate: 0 calc(var(--border-bottom-width, 4px) * 0.5)
</style>
