import * as types from '../mutation-types'

export const batchCreateCampaignImages = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'batchCreateCampaignImages')

  return new Promise((resolve, reject) => {
    model
      .batchCreateCampaignImages({
        form: form
      })
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'batchCreateCampaignImages')

        commit(types.GET_PROMOTER_CAMPAIGN_SUCCESS, response)

        dispatch(
          'promoterCampaignImages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: batchCreateCampaignImages,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              form
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyCampaignImage = ({ dispatch, commit }, { model, id }) => {
  commit(types.API_REQUEST_START, 'destroyCampaignImage')

  return new Promise((resolve, reject) => {
    model
      .destroyCampaignImage(id)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'destroyCampaignImage')

        dispatch(
          'promoterCampaignImages/destroyResourceFromRelationships',
          id,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroyCampaignImage,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              id
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
