import PromoterShareLevel from '../../resource_models/promoter_share_level'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new PromoterShareLevel(state.entities[id]))
}

export const find = (state) => (id) => {
  return new PromoterShareLevel(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const findCurrentByShareId = (state) => (shareId) => {
  return (
    all(state).find((record) => {
      return record.is_current && record.share_id === parseInt(shareId)
    }) || new PromoterShareLevel()
  )
}
