import * as types from '../mutation-types'

export const fetchRewardPartnerSettlements = (
  { dispatch, commit },
  { model, options }
) => {
  commit(types.API_REQUEST_START, 'fetchRewardPartnerSettlements')

  return new Promise((resolve, reject) => {
    model
      .fetchRewardPartnerSettlements(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchRewardPartnerSettlements')

        dispatch(
          'rewardPartnerSettlements/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchRewardPartnerSettlements,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
