import * as types from './mutation-types'
import PromoterCampaign from '../../resource_models/promoter_campaign'
export * from './promoter_campaign_share_ships/actions.js'
export * from './promoter_event_product_ships/actions'
export * from './product_categories/actions'
export * from './promoter_periods/actions.js'
export * from './slides/actions.js'
export * from './promoter_campaign_support_documents/actions.js'
export * from './promoter_campaign_images/actions.js'
export * from './promoter_campaign_main_groups/actions.js'
export * from './promotion_selections/actions.js'
export * from './promoter_campaign_sample_variants/actions.js'
export * from './promoter_campaign_sample_shipments/actions.js'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PromoterCampaign.all(options)
      .then((response) => {
        commit(types.FETCH_PROMOTER_CAMPAIGNS_SUCCESS, response)

        dispatch('partners/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterCampaignImages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PromoterCampaign.find(id)
      .then((response) => {
        commit(types.GET_PROMOTER_CAMPAIGN_SUCCESS, response)

        dispatch('promoterEvents/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('partners/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterCampaignImages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('slides/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterCampaignSupportDocuments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSupportDocuments/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignSampleVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PROMOTER_CAMPAIGN_SUCCESS, response)
        } else {
          commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PROMOTER_CAMPAIGN_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PROMOTER_CAMPAIGNS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PROMOTER_CAMPAIGN_SUCCESS, response)

    resolve(response)
  })
}

export const destroyResourceFromRelationships = ({ commit }, id, option) => {
  return new Promise((resolve, reject) => {
    commit(types.DELETE_PROMOTER_CAMPAIGN_SUCCESS, id)

    resolve(id)
  })
}

export const fetchStats = ({ dispatch, commit }, { statsKey, options }) => {
  commit(types.API_REQUEST_START, 'fetchStats')

  return new Promise((resolve, reject) => {
    PromoterCampaign.fetchStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchModelStats = (
  { dispatch, commit },
  { statsKey, options }
) => {
  commit(types.API_REQUEST_START, 'fetchModelStats')

  return new Promise((resolve, reject) => {
    PromoterCampaign.fetchModelStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchModelStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleEnabled = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'toggleEnabled')

  return new Promise((resolve, reject) => {
    model
      .toggleEnabled()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleEnabled,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleRegistrationEnabled = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'toggleRegistrationEnabled')

  return new Promise((resolve, reject) => {
    model
      .toggleRegistrationEnabled()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleRegistrationEnabled,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleHasPeriods = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'toggleHasPeriods')

  return new Promise((resolve, reject) => {
    model
      .toggleHasPeriods()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleHasPeriods,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const toggleHasSample = ({ dispatch, commit }, { model }) => {
  commit(types.API_REQUEST_START, 'toggleHasSample')

  return new Promise((resolve, reject) => {
    model
      .toggleHasSample()
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: toggleHasPeriods,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateStatsConfig = (
  { dispatch, commit },
  { model, statsConfig }
) => {
  commit(types.API_REQUEST_START, 'updateStatsConfig')

  return new Promise((resolve, reject) => {
    model
      .updateStatsConfig(statsConfig)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateStatsConfig,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const uploadAttachments = ({ dispatch, commit }, formData) => {
  commit(types.API_REQUEST_START, 'uploadAttachments')

  return new Promise((resolve, reject) => {
    PromoterCampaign.uploadAttachments(formData)
      .then((response) => {
        commit(types.UPLOAD_ATTACHMENTS_SUCCESS)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: uploadAttachments,
            ref: {
              dispatch,
              commit
            },
            params: formData
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateSample = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateSample')

  return new Promise((resolve, reject) => {
    model
      .updateSample(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSample,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createMainCampaign = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'createMainCampaign')

  return new Promise((resolve, reject) => {
    PromoterCampaign.createMainCampaign(form)
      .then((response) => {
        commit(types.ADD_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createMainCampaign,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createBuyoutCampaign = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'createBuyoutCampaign')

  return new Promise((resolve, reject) => {
    PromoterCampaign.createBuyoutCampaign(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createBuyoutCampaign,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createEventCampaign = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'createEventCampaign')

  return new Promise((resolve, reject) => {
    PromoterCampaign.createEventCampaign(form)
      .then((response) => {
        commit(types.ADD_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createEventCampaign,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const generateAiIntroduction = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'createEventCampaign')

  return new Promise((resolve, reject) => {
    PromoterCampaign.generateAiIntroduction(form)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: generateAiIntroduction,
            ref: {
              dispatch,
              commit
            },
            params: { form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfitCalculateMethod = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'updateProfitCalculateMethod')

  return new Promise((resolve, reject) => {
    model
      .updateProfitCalculateMethod(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfitCalculateMethod,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateMatchRule = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateMatchRule')

  return new Promise((resolve, reject) => {
    model
      .updateMatchRule(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateMatchRule,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateIntro = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateIntro')

  return new Promise((resolve, reject) => {
    model
      .updateIntro(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateIntro,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateTopEventProductShipIds = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'updateTopEventProductShipIds')

  return new Promise((resolve, reject) => {
    model
      .updateTopEventProductShipIds(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateTopEventProductShipIds,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const getOfficialCampaign = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'getOfficialCampaign')

  return new Promise((resolve, reject) => {
    PromoterCampaign.getOfficialCampaign()
      .then((response) => {
        commit(types.GET_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getOfficialCampaign,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const batchAcceptRegistration = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'updateTopEventProductShipIds')

  return new Promise((resolve, reject) => {
    model
      .batchAcceptRegistration(form)
      .then((response) => {
        commit(types.UPDATE_PROMOTER_CAMPAIGN_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        model.errors.record(errors)

        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: batchAcceptRegistration,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
