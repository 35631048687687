import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_campaign_images',
  attributes: [
    'id',
    'campaign_id',
    'image',
    'original_filename',
    'is_cover',
    'period_id',
    'position'
  ],
  editableAttributes: []
}

export default class PromoterCampaignImage extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  setAsCover() {
    return axios.put(`${this.apiBasePath()}/${this.id}/set_as_cover`)
  }
}
