<template lang="pug">
b-tabs.k-tabs-kolcenter-underline(
  :class="containerClass"
  :value="value"
  :expanded="expanded"
  @input="inputHandler"
  :size="size"
)
  slot
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  value: { type: [Number, String] },
  expanded: { type: Boolean },
  size: { type: String }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const containerClass = computed(() => {
  return `${props.type}-type`
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'

.k-tabs-kolcenter-underline
  .tabs
    &.is-small
      ul li[role='tab']
        font-size: font.$normal
  .tab-content
    display: none // default no content

  // expanded
  &.is-fullwidth
    .tabs.is-fullwidth ul
      gap: 0

  margin: 0
  > .tabs > ul
    border: none
    gap: size.$gap
  > .tabs > ul > li
    a
      position: relative
      color: color.$pink-swan
      font-weight: font.$semibold
      &:hover
        color: color.$ci-light
      &::after
        +utils.has-transition(.3)
        pointer-events: none
        content: ''
        position: absolute
        bottom: 0
        left: 50%
        width: 2.5rem
        transform: translate(-50%, -100%)
        height: .375rem
        background-color: color.$ci
        border-radius: .375rem
        opacity: 0
    &.is-active
      a
        color: color.$ci
        &::after
          opacity: 1
          transform: translate(-50%, 0)
</style>
