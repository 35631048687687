import * as types from '../mutation-types'

export const fetchProducts = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchProducts(options)
      .then((response) => {
        if (options.replace) {
          dispatch(
            'products/receiveResourcesFromRelationshipsWithReplace',
            response,
            {
              root: true
            }
          )
        } else {
          dispatch('products/receiveResourcesFromRelationships', response, {
            root: true
          })
        }

        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('productImages/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch('brands/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const getProduct = ({ dispatch, commit }, { model, productId }) => {
  commit(types.API_REQUEST_START, 'getProduct')

  return new Promise((resolve, reject) => {
    model
      .getProduct(productId)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })
        dispatch(
          'productVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch(
          'productOptionTypes/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        dispatch('productImages/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'getProduct')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: getProduct,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createProduct = ({ dispatch, commit }, { model, productForm }) => {
  commit(types.API_REQUEST_START, 'createProduct')

  return new Promise((resolve, reject) => {
    model
      .createProduct(productForm)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchProducts')

        resolve(response)
      })
      .catch((errors) => {
        productForm.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createProduct,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              productForm
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProduct = (
  { dispatch, commit },
  { model, productId, productForm }
) => {
  commit(types.API_REQUEST_START, 'updateProduct')

  return new Promise((resolve, reject) => {
    model
      .updateProduct(productId, productForm)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'updateProduct')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProduct,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
