import * as types from '../mutation-types'
import SiteConfig from '../../../resource_models/site_config'

export const fetchSocialMedia = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'fetchSocialMedia')

  return new Promise((resolve, reject) => {
    SiteConfig.fetchSocialMedia()
      .then((response) => {
        commit(types.GET_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchSocialMedia,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateSocialMedia = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'updateSocialMedia')

  return new Promise((resolve, reject) => {
    model
      .updateSocialMedia()
      .then((response) => {
        commit(types.UPDATE_SITE_CONFIG_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSocialMedia,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
