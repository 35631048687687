import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import AddressDataKeys from '@services/address_data_keys.json'

const JD_GATEWAY_KEY = 'jd'
const ECPAY_GATEWAY_KEY = 'ecpay'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'order_shipments',
  attributes: [
    'id',
    'tracking_code',
    'number',
    'cost',
    'shipped_at',
    'order',
    'address',
    'shipping_method',
    'shipment_items',
    'variants',
    'items',
    'state',
    'order_number',
    'adjustment_total',
    'promotion_total',
    'gateway_info',
    'latest_notification',
    'order_completed_at',
    'total_fee',
    'logistic_created_at',
    'created_at',
    'updated_at',
    'tallied_at',
    'partner_id',
    'returnable_limit_datetime',
    'jd_exported',
    'merchant_trade_no',
    'shipping_destination',
    'is_backordered',
    'preferred_shipping_date',
    'transition_state_ids'
  ],
  editableAttributes: ['address', 'tracking_code']
}

export default class OrderShipment extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchBulkLogisticLabelFormData(
    shipmentIds = [],
    providerName,
    shippingType
  ) {
    if (shipmentIds.length <= 0) return

    const requestBody = {
      data: {
        type: 'print_label_in_batches',
        attributes: {
          shipment_ids: shipmentIds,
          provider_name: providerName,
          shipping_type: shippingType
        }
      }
    }
    return axios.post(
      `${new this().apiBasePath()}/logistic_labels`,
      requestBody
    )
  }

  static tallyInBatches(shipmentIds = []) {
    if (shipmentIds.length <= 0) return

    const requestBody = {
      data: {
        type: 'tally_shipment_in_batches',
        attributes: {
          shipment_ids: shipmentIds
        }
      }
    }

    return axios.post(
      `${new this().apiBasePath()}/tally_in_batches`,
      requestBody
    )
  }

  updateTransitionStates(form) {
    return axios.put(`${this.apiBasePath()}/${this.id}/transition_states`, {
      data: {
        type: 'order_address',
        attributes: form
      }
    })
  }

  /**
   * @returns {string}
   */
  get gatewayProvider() {
    return this.gateway_info.provider
  }

  /**
   * @returns {boolean}
   */
  get isJd() {
    return this.gatewayProvider === JD_GATEWAY_KEY
  }

  /**
   * @returns {boolean}
   */
  get jdEditable() {
    return this.isJd && !this.jd_exported
  }

  /**
   * @returns {boolean}
   */
  get exportable() {
    return this.jdEditable && this.address.address.jd_shipment_info?.is_edited
  }

  /**
   * @returns {boolean}
   */
  get shipmentEditable() {
    return (
      this.canShip() &&
      !this.logistic_created_at &&
      (!this.isJd || this.jdEditable)
    )
  }

  tally() {
    return axios.post(`${this.apiBasePath()}/${this.id}/tally`)
  }

  revertTally() {
    return axios.post(`${this.apiBasePath()}/${this.id}/revert_tally`)
  }

  ship() {
    const requestBody = {
      data: {
        type: 'order_shipment_ship',
        attributes: {
          tracking_code: this.tracking_code
        }
      }
    }

    return axios.post(`${this.apiBasePath()}/${this.id}/ship`, requestBody)
  }

  static shipInBatches(shipments = []) {
    if (shipments.length <= 0) return

    const requestBody = {
      data: {
        type: 'ship_shipment_in_batches',
        attributes: {
          shipments
        }
      }
    }

    return axios.post(
      `${new this().apiBasePath()}/ship_in_batches`,
      requestBody
    )
  }

  partialShip(shipmentItemIds, trackingCode) {
    const requestBody = {
      data: {
        type: 'order_shipment_ship',
        attributes: {
          shipment_item_ids: shipmentItemIds,
          tracking_code: trackingCode
        }
      }
    }

    return axios.post(
      `${this.apiBasePath()}/${this.id}/partial_ship`,
      requestBody
    )
  }

  static existingMethodNames() {
    return axios.get(`${new this().apiBasePath()}/existing_method_names`)
  }

  updateAddress() {
    const requestBody = {
      data: {
        type: 'order_address',
        attributes: this.address
      }
    }

    return axios.put(`${this.apiBasePath()}/${this.id}/address`, requestBody)
  }

  updatePreferredShippingDate(form) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/preferred_shipping_date`,
      {
        data: {
          type: 'preferred-shipping-date',
          attributes: form
        }
      }
    )
  }

  expressMap(options = {}) {
    let optionsQueryString = Object.keys(options)
      .map((key) => `${key}=${options[key]}`)
      .join('&')

    return axios.post(
      `${this.apiBasePath()}/${this.id}/express_map?${optionsQueryString}`
    )
  }

  // extra methods or helpers here...

  stateType() {
    return {
      'is-default': this.state === 'pending',
      'is-warning': this.state === 'ready',
      'is-info': this.state === 'tallying',
      'is-success': this.state === 'shipped',
      'is-danger': this.state === 'canceled'
    }
  }

  canTally() {
    return ['ready'].includes(this.state)
  }

  get canRevertTally() {
    return ['tallying'].includes(this.state)
  }

  canShip() {
    return ['ready', 'tallying'].includes(this.state)
  }

  canPartialShip() {
    return this.canShip() && this.is_backordered
  }

  hasBackorderedItems() {
    return (
      this.shipment_items.filter((item) => item.state === 'backordered')
        .length > 0
    )
  }

  /**
   * @returns {boolean}
   */
  canApplyForRma() {
    const currentTime = Math.floor(new Date() / 1000)

    return currentTime < this.returnable_limit_datetime
  }

  fullAddress() {
    return [
      this.address.address.zip,
      this.address.address.city,
      this.address.address.area,
      this.address.address.street_address
    ]
      .filter(Boolean)
      .join(' ')
  }

  storeFullAddress() {
    return `${this.address.address.store_info.address.zip} ${this.address.address.store_info.address.city} ${this.address.address.store_info.address.area} ${this.address.address.store_info.address.street_address}`
  }

  fullPhone() {
    return ['oversea', 'oversea_billing'].includes(
      this.address.address.extra_data
    )
      ? `(${this.address.dial_code}) ${this.address.phone}`
      : this.address.phone
  }

  createLogistic(options = {}) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/create_logistic`,
      options
    )
  }

  isCvsLogistic() {
    return AddressDataKeys['cvs_data_keys'].includes(
      this.address.address.extra_data
    )
  }

  isStoreLogistic() {
    return this.address.address.extra_data === 'store'
  }

  isOverseaLogistic() {
    return this.address.address.extra_data === 'oversea'
  }

  fetchLogisticLabelFormData(options = {}) {
    return axios.post(
      `${this.apiBasePath()}/${this.id}/logistic_label`,
      options
    )
  }

  isLogisticShipment() {
    // if(!this.isDefaultShipment()) return false // 暫時開放非預設廠商使用第三方物流服務

    return [ECPAY_GATEWAY_KEY, JD_GATEWAY_KEY].includes(this.gatewayProvider)
  }

  isAbleToCreateLogistic() {
    return !this.logistic_created_at && this.isLogisticShipment() && !this.isJd
  }

  get isAbleToSetJdLogistic() {
    return (
      !this.logistic_created_at && this.isLogisticShipment() && this.jdEditable
    )
  }

  isAbleToPrintLogisticLabel() {
    return !!this.logistic_created_at && this.isLogisticShipment() && !this.isJd
  }

  isAbleToShip() {
    if (!this.canShip()) return false

    if (this.isLogisticShipment()) {
      return !!this.logistic_created_at
    } else {
      return true
    }
  }

  batchOperationCheck(mode) {
    if (!mode) return false

    switch (mode) {
      case 'tally':
        return this.canTally()
      case 'createLogistic':
        // only cvs logistic can create via batch
        return (
          this.isLogisticShipment() &&
          this.isAbleToCreateLogistic() &&
          this.isCvsLogistic()
        )
      case 'exportJdShipment':
        return this.exportable
      case 'printLogisticLabel':
        return this.isAbleToPrintLogisticLabel()
      case 'printShippingList':
        return true
      case 'ship':
        return this.canShip() && !this.isLogisticShipment()
    }
  }

  trackingCode() {
    let data = this.latest_notification && this.latest_notification.information

    if (!data) return

    switch (this.gatewayProvider) {
      case ECPAY_GATEWAY_KEY:
        return (
          (this.isCvsLogistic() ? data.cvs_payment_no : data.booking_note) || ''
        )
      case JD_GATEWAY_KEY:
        return data.logistics_order_no
      default:
        return
    }
  }

  isDefaultShipment() {
    return this.partner_id == 1
  }

  cvsInfo() {
    return this.address.address.cvs_store_info
  }

  storeInfo() {
    return this.address.address.store_info
  }
}
