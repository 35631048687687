import * as types from '../mutation-types'
import Partner from '@models/partner'

export const fetchInvoices = ({ dispatch, commit }, options = {}) => {
  commit(types.API_REQUEST_START, 'fetchInvoices')

  return new Promise((resolve, reject) => {
    Partner.fetchInvoices(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchInvoices')
        dispatch(
          'oddPayInvoices/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchInvoices,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const currentNormalInvoice = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'currentNormalInvoice')

  return new Promise((resolve, reject) => {
    Partner.currentNormalInvoice()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'currentNormalInvoice')
        dispatch(
          'oddPayInvoices/getCurrentNormalInvoiceFromRelationship',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: currentNormalInvoice,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const currentSubscriptionInvoice = ({ dispatch, commit }) => {
  commit(types.API_REQUEST_START, 'currentSubscriptionInvoice')

  return new Promise((resolve, reject) => {
    Partner.currentSubscriptionInvoice()
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'currentSubscriptionInvoice')
        dispatch(
          'oddPayInvoices/getCurrentSubscriptionInvoiceFromRelationship',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: currentSubscriptionInvoice,
            ref: {
              dispatch,
              commit
            },
            params: {}
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const confirmOddPayInvoice = ({ dispatch, commit }, oddPayInvoice) => {
  commit(types.API_REQUEST_START, 'confirmOddPayInvoice')

  return new Promise((resolve, reject) => {
    Partner.confirmOddPayInvoice(oddPayInvoice)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'confirmOddPayInvoice')
        dispatch('oddPayInvoices/getResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        oddPayInvoice.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: confirmOddPayInvoice,
            ref: {
              dispatch,
              commit
            },
            params: oddPayInvoice
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const makePaymentForOddPayInvoice = (
  { dispatch, commit },
  oddPayInvoice
) => {
  commit(types.API_REQUEST_START, 'makePaymentForOddPayInvoice')

  return new Promise((resolve, reject) => {
    Partner.makePaymentForOddPayInvoice(oddPayInvoice)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'makePaymentForOddPayInvoice')
        dispatch('oddPayInvoices/getResourceFromRelationship', response, {
          root: true
        })

        resolve(response)
      })
      .catch((errors) => {
        oddPayInvoice.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: makePaymentForOddPayInvoice,
            ref: {
              dispatch,
              commit
            },
            params: oddPayInvoice
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const choosePlan = ({ dispatch, commit }, oddPayInvoice) => {
  commit(types.API_REQUEST_START, 'choosePlan')

  return new Promise((resolve, reject) => {
    Partner.choosePlan(oddPayInvoice)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'choosePlan')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: choosePlan,
            ref: {
              dispatch,
              commit
            },
            params: oddPayInvoice
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchOddPayInvoices = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchOddPayInvoices')

  return new Promise((resolve, reject) => {
    model
      .fetchOddPayInvoices(options)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'fetchOddPayInvoices')
        dispatch(
          'oddPayInvoices/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchOddPayInvoices,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const payOddPayInvoiceByCash = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'payOddPayInvoiceByCash')

  return new Promise((resolve, reject) => {
    model
      .payOddPayInvoiceByCash(form)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'payOddPayInvoiceByCash')
        dispatch(
          'oddPayInvoices/receiveResourcesFromRelationshipsWithReplace',
          response,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)

        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: payOddPayInvoiceByCash,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
