import * as types from '../mutation-types'

export const updateProfileAccount = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'signUp')

  return new Promise((resolve, reject) => {
    model
      .updateProfileAccount()
      .then((response) => {
        commit(types.ADD_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfileAccount,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfileMembers = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'signUp')

  return new Promise((resolve, reject) => {
    model
      .updateProfileMembers(form)
      .then((response) => {
        commit(types.ADD_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfileMembers,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfilePassword = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'signUp')

  return new Promise((resolve, reject) => {
    model
      .updateProfilePassword()
      .then((response) => {
        commit(types.ADD_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfilePassword,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfileBasicInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'signUp')

  return new Promise((resolve, reject) => {
    model
      .updateProfileBasicInfo()
      .then((response) => {
        commit(types.ADD_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfileBasicInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfileBankAccount = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'signUp')

  return new Promise((resolve, reject) => {
    model
      .updateProfileBankAccount()
      .then((response) => {
        commit(types.ADD_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfileBankAccount,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfileContact = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'signUp')

  return new Promise((resolve, reject) => {
    model
      .updateProfileContact()
      .then((response) => {
        commit(types.ADD_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfileContact,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateProfileAdvancedInfo = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'signUp')

  return new Promise((resolve, reject) => {
    model
      .updateProfileAdvancedInfo()
      .then((response) => {
        commit(types.ADD_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateProfileAdvancedInfo,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const updateAdminNote = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateAdminNote')

  return new Promise((resolve, reject) => {
    model
      .updateAdminNote(form)
      .then((response) => {
        commit(types.UPDATE_PARTNER_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateAdminNote,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
