import * as types from '../mutation-types'

export const fetchSlides = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchSlides')

  return new Promise((resolve, reject) => {
    model
      .fetchSlides(options)
      .then((response) => {
        dispatch(
          'slides/receiveResourcesFromRelationshipsWithReplace',
          response,
          { root: true }
        )
        commit(types.API_REQUEST_SUCCESS, 'fetchSlides')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchSlides,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const saveSlide = ({ dispatch, commit }, { model, slide, formData }) => {
  commit(types.API_REQUEST_START, 'saveSlide')

  return new Promise((resolve, reject) => {
    model
      .saveSlide(slide, formData)
      .then((response) => {
        dispatch('slides/receiveResourcesFromRelationships', response, {
          root: true
        })
        commit(types.API_REQUEST_SUCCESS, 'saveSlide')

        resolve(response)
      })
      .catch((errors) => {
        slide.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: saveSlide,
            ref: {
              dispatch,
              commit
            },
            params: { model, slide, formData }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroySlide = ({ dispatch, commit }, { model, slide }) => {
  commit(types.API_REQUEST_START, 'destroySlide')

  return new Promise((resolve, reject) => {
    model
      .destroySlide(slide)
      .then((response) => {
        dispatch('slides/removeResourceFromRelationships', slide.id, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'destroySlide')
        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroySlide,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              slide
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
