import * as types from '../mutation-types'

export const fetchPromoterMatchProducts = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchPromoterMatchProducts')

  return new Promise((resolve, reject) => {
    model
      .fetchPromoterMatchProducts(options)
      .then((response) => {
        dispatch('products/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'productCategories/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterMatchProducts/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchPromoterMatchProducts')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPromoterMatchProducts,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchPromoterMatchProductStats = (
  { dispatch, commit },
  { model, statsKey, options }
) => {
  commit(types.API_REQUEST_START, 'fetchPromoterMatchProductStats')
  return new Promise((resolve, reject) => {
    model
      .fetchPromoterMatchProductStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPromoterMatchProductStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
