import * as types from '../mutation-types'

export const fetchBrands = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchBrands')

  return new Promise((resolve, reject) => {
    model
      .fetchBrands(options)
      .then((response) => {
        dispatch('brands/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchBrands')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchBrands,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
