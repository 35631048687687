import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_event_product_ships',
  attributes: [
    'id',
    'promoter_event_id',
    'campaign_id',
    'product_id',
    'min_profit_ratio',
    'max_profit_ratio',
    'property_shipping_condition',
    'property_reserved_date',
    'property_gift_note',
    'property_free_shipment_condition',
    'is_available',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'variant_ships',
    'min_profit_ratio',
    'max_profit_ratio',
    'property_shipping_condition',
    'property_reserved_date',
    'property_gift_note',
    'property_free_shipment_condition'
  ]
}

export default class PromoterEventProductShip extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  fetchShareProductShips(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/share_product_ships?${FetchingDataOptionsService.call(options)}`
    )
  }

  toggleAvailable() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_available`)
  }
}
