<template lang="pug">
div(
  :is="component"
  :value="value"
  :expanded="expanded"
  :size="size"
  :options="options"
  @input="inputHandler"
)
  slot
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'
import KolcenterUnderline from './k_tabs/kolcenter-underline.vue'
import KolcenterDot from './k_tabs/kolcenter-dot.vue'
import KolnetRounded from './k_tabs/kolnet-rounded.vue'
import KolnetRoundedV2 from './k_tabs/kolnet-rounded-v2.vue'
import KolnetText from './k_tabs/kolnet-text.vue'
import IsBoxed from './k_tabs/is-boxed.vue'
import IsToggle from './k_tabs/is-toggle.vue'
import IsToggleRounded from './k_tabs/is-toggle-rounded.vue'
import IsUnderline from './k_tabs/is-underline.vue'

const COMPONENT_MAP = {
  'kolcenter-underline': KolcenterUnderline,
  'kolcenter-dot': KolcenterDot,
  'kolnet-rounded': KolnetRounded,
  'kolnet-rounded-v2': KolnetRoundedV2,
  'kolnet-text': KolnetText,
  'is-boxed': IsBoxed,
  'is-toggle': IsToggle,
  'is-toggle-rounded': IsToggleRounded,
  'is-underline': IsUnderline
}

const props = defineProps({
  styleType: { type: String },
  value: { type: [Number, String] },
  expanded: { type: Boolean },
  size: { type: String },
  options: { type: Object }
})

const component = computed(() => {
  return COMPONENT_MAP[props.styleType]
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}
</script>
