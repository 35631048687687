export const FETCH_PROMOTER_MATCH_ANSWERS_SUCCESS =
  'FETCH_PROMOTER_MATCH_ANSWERS_SUCCESS'
export const GET_RELATED_PROMOTER_MATCH_ANSWERS_SUCCESS =
  'GET_RELATED_PROMOTER_MATCH_ANSWERS_SUCCESS'
export const GET_PROMOTER_MATCH_ANSWER_SUCCESS =
  'GET_PROMOTER_MATCH_ANSWER_SUCCESS'
export const ADD_PROMOTER_MATCH_ANSWER_SUCCESS =
  'ADD_PROMOTER_MATCH_ANSWER_SUCCESS'
export const UPDATE_PROMOTER_MATCH_ANSWER_SUCCESS =
  'UPDATE_PROMOTER_MATCH_ANSWER_SUCCESS'
export const DELETE_PROMOTER_MATCH_ANSWER_SUCCESS =
  'DELETE_PROMOTER_MATCH_ANSWER_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
