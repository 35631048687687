import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'partner_company_infos',
  attributes: [
    'id',
    'name',
    'eng_name',
    'ein',
    'business_certificate',
    'address',
    'owner',
    'tel',
    'fax',
    'mobile',
    'contact_name',
    'contact_phone',
    'contact_mobile',
    'contact_email',
    'contact_lineid',
    'website',
    'main_product_intro',
    'contact_address',
    'bank_code',
    'bank_name',
    'bank_branch_name',
    'bank_account_number',
    'bank_account_name',
    'product_liability_insurance_no',
    'fda_register_no',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'name',
    'eng_name',
    'ein',
    'business_certificate',
    'address',
    'owner',
    'tel',
    'fax',
    'mobile',
    'contact_name',
    'contact_phone',
    'contact_email',
    'contact_lineid',
    'website',
    'main_product_intro',
    'contact_address',
    'bank_code',
    'bank_name',
    'bank_branch_name',
    'bank_account_number',
    'product_liability_insurance_no',
    'fda_register_no'
  ]
}

export default class PartnerCompanyInfo extends ResourceModelBase {
  constructor(attributes = {}) {
    if (!attributes.address)
      attributes.address = {
        city: null,
        area: null,
        zip: null,
        street_address: null
      }
    if (!attributes.contact_address)
      attributes.contact_address = {
        city: null,
        area: null,
        zip: null,
        street_address: null,
        is_same_as_address: false
      }

    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
