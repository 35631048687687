<template lang="pug">
b-input.k-input-kolcener(
  :value="value"
  :placeholder="placeholder"
  :type="type"
  :size="size"
  :icon="icon"
  :icon-pack="iconPack"
  :icon-right="iconRight"
  :expanded="expanded"
  :disabled="disabled"
  :readonly="readonly"
  :max="max"
  :min="min"
  @input="inputHandler"
)
</template>

<script setup>
import { reactive, ref, computed } from 'vue'

const props = defineProps({
  value: { type: [String, Number], required: false },
  type: { type: String, required: false },
  size: { type: String, required: false },
  placeholder: { type: String, required: false },
  expanded: { type: Boolean, required: false },
  iconPack: {
    type: String,
    required: false,
    validator(value) {
      return ['mdi', 'fa', 'fas', 'far', 'fad'].includes(value)
    }
  },
  icon: { type: String, required: false },
  iconRight: { type: String, required: false },
  disabled: { type: Boolean, required: false },
  readonly: { type: Boolean, required: false },
  max: { type: Number, required: false },
  min: { type: Number, required: false }
})

const emit = defineEmits(['input'])

function inputHandler(value) {
  emit('input', value)
}
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/size'

.k-input-kolcener
  input.input
    +utils.reset-buefy-form-styles
    padding-block: calc(0.5em - 1px)
    padding-inline: calc(0.75em - 1px)
    text-align: center
    font-size: font.$normal
    line-height: 1.5
</style>
