<template lang="pug">
b-modal.vc-modal-modal-link-pop-modal(
  :active.sync="isImageModalActive"
  @update:active="updateActiveHandler"
)
  b-carousel(
    :pause-info="false"
    :interval="7000"
    :arrow="false"
    :indicator-inside="false"
  )
    b-carousel-item(
      v-for="(data, index) in modalAds"
      :key="index"
    )
      a.link(:href="data.url")
        img.img(
          :src="isMobile ? data.mobile_image.url || data.image.url : data.image.url"
          :alt="data.alt_text"
        )
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'
import { useStore } from 'skid-composables'

const MOBILE_IMAGE_BREAK_POINT = 769
const store = useStore()

// props
const props = defineProps<{
  active: Boolean
  useType: String
}>()

// emit
const emit = defineEmits(['update:active'])

// data
const isImageModalActive = ref<Boolean>(null)

watch(
  () => props.active,
  (newVal) => {
    isImageModalActive.value = newVal
  }
)

// computed
/**
 *
 * workaround for safari can't get window width from vuex at first time loading
 *
 * @returns {computedRef<Boolean>}
 */
const isMobile = computed<Boolean>(() => {
  return (
    (store.getters['windowWidth'] || window.innerWidth) <
    MOBILE_IMAGE_BREAK_POINT
  )
})
/**
 * @returns {computedRef<Array<Object>>}
 */
const modalAds = computed<Array<Object>>(() => {
  return store.getters['modalLinks/allByType'](props.useType)
    .filter((link) => link.isLive)
    .sort((a, b) => a.position - b.position)
})

// mounted
onMounted(async () => {
  await _fetchModalLinks()

  if (modalAds.value.length === 0) {
    isImageModalActive.value = false
  } else {
    isImageModalActive.value = props.active
  }
})

// methods
/**
 * @returns {void}
 */
const _fetchModalLinks = async () => {
  await store.dispatch('modalLinks/allByUseType', {
    filter: props.useType
  })
}

const updateActiveHandler = (value: boolean) => {
  isImageModalActive.value = value

  emit('update:active', isImageModalActive.value)
}
</script>

<style lang="sass">
@use '@shared/css/abstracts/size.sass'

.vc-modal-modal-link-pop-modal
  img
    border-radius: size.$radius-normal
</style>
