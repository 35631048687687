import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_levels',
  attributes: [
    'id',
    'en_name',
    'zh_name',
    'level',
    'profit_ratio',
    'goal_campaign_share_ships_count',
    'goal_sales_total',
    'image',
    'duration_days',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'en_name',
    'zh_name',
    'profit_ratio',
    'goal_campaign_share_ships_count',
    'new_goal_sales_total'
  ]
}

export default class PromoterLevel extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static REVENUE_DELAY_DAYS = 10 // Sync to promoter/level.rb

  displayInfo() {
    return `${this.zh_name} (${this.profit_ratio}%)`
  }
}
