export const FETCH_PRODUCT_CATEGORIES_SUCCESS =
  'FETCH_PRODUCT_CATEGORIES_SUCCESS'
export const FETCH_PRODUCT_CATEGORIES_FOR_SEARCH_SUCCESS =
  'FETCH_PRODUCT_CATEGORIES_FOR_SEARCH_SUCCESS'
export const FETCH_PRODUCT_CATEGORY_SELECT_GOODS_SUCCESS =
  'FETCH_PRODUCT_CATEGORY_SELECT_GOODS_SUCCESS'
export const GET_RELATED_PRODUCT_CATEGORIES_SUCCESS =
  'GET_RELATED_PRODUCT_CATEGORIES_SUCCESS'
export const GET_PRODUCT_CATEGORY_SUCCESS = 'GET_PRODUCT_CATEGORY_SUCCESS'
export const ADD_PRODUCT_CATEGORY_SUCCESS = 'ADD_PRODUCT_CATEGORY_SUCCESS'
export const UPDATE_PRODUCT_CATEGORY_SUCCESS = 'UPDATE_PRODUCT_CATEGORY_SUCCESS'
export const DELETE_PRODUCT_CATEGORY_SUCCESS = 'DELETE_PRODUCT_CATEGORY_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
