export const FETCH_PARTNERS_SUCCESS = 'FETCH_PARTNERS_SUCCESS'
export const GET_RELATED_PARTNERS_SUCCESS = 'GET_RELATED_PARTNERS_SUCCESS'
export const GET_PARTNER_SUCCESS = 'GET_PARTNER_SUCCESS'
export const ADD_PARTNER_SUCCESS = 'ADD_PARTNER_SUCCESS'
export const UPDATE_PARTNER_SUCCESS = 'UPDATE_PARTNER_SUCCESS'
export const DELETE_PARTNER_SUCCESS = 'DELETE_PARTNER_SUCCESS'
export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'

export const SET_CURRENT_PARTNER_ID = 'SET_CURRENT_PARTNER_ID'
