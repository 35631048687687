import PromoterMatchProduct from '../../resource_models/promoter_match_product'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map((id) => new PromoterMatchProduct(state.entities[id]))
}

export const find = (state) => (id) => {
  return new PromoterMatchProduct(state.entities[id])
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}

export const findByProductId = (state) => (productId) => {
  return (
    all(state).find((record) => {
      return record.product_id === parseInt(productId)
    }) ||
    new PromoterMatchProduct({ product_id: productId, is_available: false })
  )
}

export const statsData =
  (state) =>
  (scope = null) => {
    return state.stats[scope] ? state.stats[scope].data : []
  }
