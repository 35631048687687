import * as types from './mutation-types'
import PartnerShippingMethodShip from '../../resource_models/partner_shipping_method_ship'

export const all = ({ dispatch, commit }, options) => {
  commit(types.API_REQUEST_START, 'all')

  return new Promise((resolve, reject) => {
    PartnerShippingMethodShip.all(options)
      .then((response) => {
        commit(types.FETCH_PARTNER_SHIPPING_METHOD_SHIPS_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: all,
            ref: {
              dispatch,
              commit
            },
            params: options
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const find = ({ dispatch, commit }, id) => {
  commit(types.API_REQUEST_START, 'find')

  return new Promise((resolve, reject) => {
    PartnerShippingMethodShip.find(id)
      .then((response) => {
        commit(types.GET_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: find,
            ref: {
              dispatch,
              commit
            },
            params: id
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const save = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'save')

  return new Promise((resolve, reject) => {
    model
      .save()
      .then((response) => {
        if (model.isNewRecord()) {
          commit(types.ADD_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS, response)
        } else {
          commit(types.UPDATE_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS, response)
        }

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: save,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroy = ({ dispatch, commit }, model) => {
  commit(types.API_REQUEST_START, 'destroy')

  return new Promise((resolve, reject) => {
    model
      .destroy()
      .then((response) => {
        commit(types.DELETE_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS, model.id)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroy,
            ref: {
              dispatch,
              commit
            },
            params: model
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const receiveResourcesFromRelationships = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_RELATED_PARTNER_SHIPPING_METHOD_SHIPS_SUCCESS, response)

    resolve(response)
  })
}

export const getResourceFromRelationship = ({ commit }, response) => {
  return new Promise((resolve, reject) => {
    commit(types.GET_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS, response)

    resolve(response)
  })
}

export const receiveDestroyResourceFromRelationships = ({ commit }, id) => {
  return new Promise((resolve, reject) => {
    commit(types.DELETE_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS, id)

    resolve()
  })
}

export const updatePreferredShippingDates = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'updatePreferredShippingDates')

  return new Promise((resolve, reject) => {
    model
      .updatePreferredShippingDates(form)
      .then((response) => {
        commit(types.UPDATE_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS, response)

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updatePreferredShippingDates,
            ref: {
              dispatch,
              commit
            },
            params: { model, form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
