import * as types from '../mutation-types'

export const updateSampleVariant = ({ dispatch, commit }, { model, form }) => {
  commit(types.API_REQUEST_START, 'updateSampleVariant')

  return new Promise((resolve, reject) => {
    model
      .updateSampleVariant(form)
      .then((response) => {
        dispatch(
          'promoterCampaignSampleVariants/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'updateSampleVariant')

        resolve(response)
      })
      .catch((errors) => {
        model.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateSampleVariant,
            ref: {
              dispatch,
              commit
            },
            params: { model, sampleVariants }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
