<template lang="pug">
div(
  :is="component"
  :type="type"
  :value="value"
  :nativeValue="nativeValue"
  :disabled="disabled"
  @input="inputHandler"
)
  slot
</template>

<script setup>
import { computed } from 'vue'
import isBCheckbox from './k_checkbox/is-b-checkbox.vue'
import IsAnimateCheck from './k_checkbox/is-animate-check.vue'

const STYLE_TYPE_MAP = {
  'is-b-checkbox': isBCheckbox,
  'is-animate-check': IsAnimateCheck
}

const props = defineProps({
  styleType: { type: String, default: () => 'is-b-checkbox' },
  type: { type: String },
  value: { required: false },
  nativeValue: { required: false },
  disabled: { type: Boolean }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})
</script>
