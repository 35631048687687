import * as types from '../mutation-types'

export const updateTransitionStates = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'updateTransitionStates')

  return new Promise((resolve, reject) => {
    model
      .updateTransitionStates(form)
      .then((response) => {
        commit(types.UPDATE_ORDER_SHIPMENT_SUCCESS, response)

        commit(types.API_REQUEST_SUCCESS, 'updateTransitionStates')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: updateTransitionStates,
            ref: {
              dispatch,
              commit
            },
            params: { form }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
