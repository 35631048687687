<template lang="pug">
b-tabs.k-tabs-kolnet-rounded(
  :class="containerClass"
  :value="value"
  :expanded="expanded"
  @input="inputHandler"
  :size="size"
)
  slot
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  value: { type: [Number, String] },
  expanded: { type: Boolean },
  size: { type: String }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const containerClass = computed(() => {
  return `${props.type}-type`
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'

.k-tabs-kolnet-rounded
  $indicator-size: .5rem
  margin: size.$gap * 0.25 size.$gap
  $border-width: 4px

  .tabs
    &.is-small
      ul li[role='tab']
        font-size: font.$normal
  .tab-content
    display: none // default no content

  // expanded
  &.is-fullwidth
    .tabs.is-fullwidth ul
      gap: 0

  position: relative
  width: 100%
  display: flex
  justify-content: center
  &:after
    position: absolute
    bottom: $border-width * -1
    left: 0
    width: 100%
    height: 4px
    content: ''
    background: color.$ci
    z-index: 3
  .tabs
    overflow: visible
    ul li
      margin-left: -5px
      margin-right: -5px
      position: relative
      z-index: 1
      &.is-active
        z-index: 2
    ul li a
      width: 220px
      border: solid 3px #f1f3f3
      background: color.$white
      color: color.$lighter
      border-top-left-radius: 23px
      border-top-right-radius: 23px
      &:focus
        border-color: #f1f3f3
    ul li.is-active a
      border: solid #{$border-width} #f1f3f3
      color: color.$white
      background-image: linear-gradient(to bottom, #00dcff, #00d1ff 42%, #00b6ff)
</style>
