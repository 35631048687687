import * as types from '../mutation-types'

export const fetchPromoterCampaigns = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchPromoterCampaigns')

  return new Promise((resolve, reject) => {
    model
      .fetchPromoterCampaigns(options)
      .then((response) => {
        dispatch(
          'promoterCampaigns/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch(
          'promoterCampaignImages/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchPromoterCampaigns')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPromoterCampaigns,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const createPromoterCampaign = (
  { dispatch, commit },
  { model, form }
) => {
  commit(types.API_REQUEST_START, 'createPromoterCampaign')

  return new Promise((resolve, reject) => {
    model
      .createPromoterCampaign(form)
      .then((response) => {
        dispatch(
          'promoterCampaigns/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'createPromoterCampaign')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        form.errors.record(errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: createPromoterCampaign,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              form
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
