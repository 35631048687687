<template lang="pug">
b-tabs.k-tabs-kolcenter-dot(
  :class="containerClass"
  :value="value"
  :expanded="expanded"
  @input="inputHandler"
  :size="size"
)
  slot
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  value: { type: [Number, String] },
  expanded: { type: Boolean },
  size: { type: String }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const containerClass = computed(() => {
  return `${props.type}-type`
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'

.k-tabs-kolcenter-dot
  $indicator-size: .5rem
  margin: size.$gap * 0.25 size.$gap

  .tabs
    &.is-small
      ul li[role='tab']
        font-size: font.$normal
  .tab-content
    display: none // default no content

  // expanded
  &.is-fullwidth
    .tabs.is-fullwidth ul
      gap: 0

  > .tabs > ul
    border: none
    gap: size.$gap * 2

  > .tabs > ul > li
    +utils.is-clickable
    margin: 0 size.$gap
    flex-shrink: 0
    > a
      padding: 0
    > a > span
      position: relative
      color: color.$pink-swan
      &:hover
        color: color.$ci-light
      &::before
        +utils.is-circle($indicator-size)
        +utils.has-transition(.3)
        content: ''
        position: absolute
        top: 50%
        left: 100%
        transform: translateY(-50%)
        display: inline-block
        background-color: color.$ci
        opacity: 0
    &.is-active
      > a > span
        color: color.$ci
        &:hover
          color: color.$ci
        &::before
          left: -($indicator-size * 2)
          opacity: 1
</style>
