<template lang="pug">
b-tabs.k-tabs-kolnet-text(
  :class="containerClass"
  :value="value"
  :expanded="expanded"
  @input="inputHandler"
  :size="size"
)
  slot
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  value: { type: [Number, String] },
  expanded: { type: Boolean },
  size: { type: String }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const containerClass = computed(() => {
  return `${props.type}-type`
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/font.sass'
@use '@shared/css/abstracts/size.sass'

.k-tabs-kolnet-text
  $indicator-size: .5rem
  $border-width: 4px
  --font-size: #{font.$small}

  .tab-content
    display: none // default no content

  // expanded
  &.is-fullwidth
    .tabs.is-fullwidth ul
      gap: 0

  position: relative
  width: 100%
  display: flex
  justify-content: center
  .tabs
    overflow: visible
    ul
      border-bottom: none
      gap: 3rem
    ul li
      position: relative
      z-index: 1
      &.is-active
        z-index: 2
    ul li a
      font-size: var(--font-size)
      color: color.$gunsmoke
      border-bottom: none
      font-weight: font.$semibold
    ul li.is-active a
      color: color.$ci
</style>
