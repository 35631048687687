<template lang="pug">
b-checkbox.is-b-checkbox(
  :value="value"
  :native-value="nativeValue"
  :disabled="disabled"
  @input="inputHandler"
  :class="containerClass"
)
  slot
</template>

<script setup>
import { computed } from 'vue'

const STYLE_TYPE_MAP = {
  'is-ci': 'is-ci',
  'is-primary': 'is-primary'
}

const props = defineProps({
  type: { type: String, default: () => 'is-ci' },
  value: { required: false },
  nativeValue: { required: false },
  disabled: { type: Boolean }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

const containerClass = computed(() => {
  return [STYLE_TYPE_MAP[props.type]].filter(Boolean).join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/size'

.is-b-checkbox.checkbox
  cursor: pointer
  &.bordered
    border: 1px solid color.$concrete

  &.is-ci
    &:hover input[type=checkbox]:not(:disabled) + .check
      border-color: color.$ci
    input[type=checkbox]:checked+.check
      border-color: color.$ci
      background-color: color.$ci

  &.is-info
    &:hover input[type=checkbox]:not(:disabled) + .check
      border-color: color.$info
    input[type=checkbox]:checked+.check
      border-color: color.$info
      background-color: color.$info
</style>
