<template lang="pug">
k-select.k-city-select(
  :value="value"
  :disabled="disabled"
  @input="inputHandler"
  :expanded="expanded"
  :placeholder="placeholder"
  :style-type="styleType"
)
  option(
    value=""
    disabled
    selected
  ) {{ actionLocaleText('choose_one') }}
  option(
    v-for="(city, index) in cities"
    :key="index"
    :value="city"
  ) {{ city }}
</template>

<script setup>
import { computed } from 'vue'
import TaiwanZipCodes from '@services/taiwan_zip_codes.json'
import KSelect from './k-select.vue'

const props = defineProps({
  value: { type: String },
  disabled: { type: Boolean },
  expanded: { type: Boolean },
  placeholder: { type: String, default: () => '請選擇縣市' },
  styleType: { type: String }
})

const emit = defineEmits(['input'])

const cities = computed(() => {
  return Object.keys(TaiwanZipCodes)
})

const inputHandler = (value) => {
  emit('input', value)
}
</script>
