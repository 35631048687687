<template lang="pug">
k-select.k-area-select(
  :value="area"
  autocomplete="region"
  :disabled="disabled"
  @input="inputHandler"
  :placeholder="placeholder"
  :expanded="expanded"
  :styleType="styleType"
)
  option(
    v-for="(area, index) in areas"
    :key="index"
    :value="area"
  ) {{ area }} ({{ getZipCode(area) }})
</template>

<script setup>
import { computed, watch, onMounted } from 'vue'
import TaiwanZipCodes from '@services/taiwan_zip_codes.json'
import KSelect from './k-select.vue'

const props = defineProps({
  city: { type: String },
  area: { type: String },
  zip: { type: [String, Number] },
  disabled: { type: Boolean },
  expanded: { type: Boolean },
  placeholder: { type: String, default: () => '請選擇...' },

  styleType: { type: String }
})

const emit = defineEmits(['update:area', 'update:zip', 'input'])

const cities = computed(() => {
  return Object.keys(TaiwanZipCodes)
})

const areaZips = computed(() => {
  if (!props.city) return {}

  return TaiwanZipCodes[props.city]
})

const getZipCode = (area) => {
  return areaZips.value[area]
}

const areas = computed(() => {
  return Object.keys(areaZips.value)
})

const currentCity = computed(() => {
  return props.city
})

watch(currentCity, () => {
  if (!areas.value.includes(props.area)) emit('update:area', '')
})

const inputHandler = (value) => {
  emit('update:area', value)
  emit('update:zip', getZipCode(value))
  emit('input', value)
}

onMounted(() => {
  if (props.area && !props.zip) emit('update:zip', getZipCode(props.area))
})
</script>
