import * as types from '../mutation-types'

export const fetchPeriods = ({ dispatch, commit }, { model, options = {} }) => {
  commit(types.API_REQUEST_START, 'fetchPeriods')

  return new Promise((resolve, reject) => {
    model
      .fetchPeriods(options)
      .then((response) => {
        dispatch(
          'promoterCampaignPeriods/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchPeriods')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPeriods,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const savePeriod = ({ dispatch, commit }, { model, period }) => {
  commit(types.API_REQUEST_START, 'savePeriod')

  return new Promise((resolve, reject) => {
    model
      .savePeriod(period)
      .then((response) => {
        dispatch(
          'promoterCampaignPeriods/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'savePeriod')

        resolve(response)
      })
      .catch((errors) => {
        period.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: savePeriod,
            ref: {
              dispatch,
              commit
            },
            params: { model, period }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyPeriod = ({ dispatch, commit }, { model, id }) => {
  commit(types.API_REQUEST_START, 'destroyPeriod')

  return new Promise((resolve, reject) => {
    model
      .destroyPeriod(id)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'destroyPeriod')

        dispatch(
          'promoterCampaignPeriods/receiveDestroyResourceFromRelationships',
          id,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroyPeriod,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              id
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
