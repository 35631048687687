export const FETCH_PROMOTER_CAMPAIGNS_SUCCESS =
  'FETCH_PROMOTER_CAMPAIGNS_SUCCESS'
export const GET_RELATED_PROMOTER_CAMPAIGNS_SUCCESS =
  'GET_RELATED_PROMOTER_CAMPAIGNS_SUCCESS'
export const GET_PROMOTER_CAMPAIGN_SUCCESS = 'GET_PROMOTER_CAMPAIGN_SUCCESS'
export const ADD_PROMOTER_CAMPAIGN_SUCCESS = 'ADD_PROMOTER_CAMPAIGN_SUCCESS'
export const UPDATE_PROMOTER_CAMPAIGN_SUCCESS =
  'UPDATE_PROMOTER_CAMPAIGN_SUCCESS'
export const DELETE_PROMOTER_CAMPAIGN_SUCCESS =
  'DELETE_PROMOTER_CAMPAIGN_SUCCESS'

export const UPLOAD_ATTACHMENTS_SUCCESS = 'UPLOAD_ATTACHMENTS_SUCCESS'

export const GET_STATS_SUCCESS = 'GET_STATS_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_SUCCESS = 'API_REQUEST_SUCCESS'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
