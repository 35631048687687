export const FETCH_PARTNER_SHIPPING_METHOD_SHIPS_SUCCESS =
  'FETCH_PARTNER_SHIPPING_METHOD_SHIPS_SUCCESS'
export const GET_RELATED_PARTNER_SHIPPING_METHOD_SHIPS_SUCCESS =
  'GET_RELATED_PARTNER_SHIPPING_METHOD_SHIPS_SUCCESS'
export const GET_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS =
  'GET_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS'
export const ADD_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS =
  'ADD_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS'
export const UPDATE_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS =
  'UPDATE_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS'
export const DELETE_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS =
  'DELETE_PARTNER_SHIPPING_METHOD_SHIP_SUCCESS'

export const API_REQUEST_START = 'API_REQUEST_START'
export const API_REQUEST_FAIL = 'API_REQUEST_FAIL'
