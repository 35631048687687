<template lang="pug">
label.k-checkbox-is-animate-check
  input(
    type="checkbox"
    :value="value"
    @input="inputHandler"
  )
  .check-label
    .check-icon
    slot
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({
  type: { type: String, default: () => 'is-ci' },
  value: { required: false },
  nativeValue: { required: false },
  disabled: { type: Boolean }
})

const emit = defineEmits(['input'])

const inputHandler = (event) => {
  console.log('change', event.target.checked)
  emit('input', event.target.checked)
}

const containerClass = computed(() => {
  return [STYLE_TYPE_MAP[props.type]].filter(Boolean).join(' ')
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color'
@use '@shared/css/abstracts/font'
@use '@shared/css/abstracts/size'

.k-checkbox-is-animate-check
  --dimension: 1.5rem
  position: relative
  padding: .5rem 0
  display: inline-block

  input[type='checkbox']
    height: 0
    width: 0
    position: absolute

  input[type='checkbox'] + .check-label
    position: relative
    display: flex
    margin: 0
    align-items: center
    color: #9e9e9e
    transition: color 250ms cubic-bezier(.4,.0,.23,1)

  input[type='checkbox'] + .check-label > .check-icon
    display: flex
    justify-content: center
    align-items: center
    margin-right: .5rem
    width: var(--dimension)
    height: var(--dimension)
    background: transparent
    border: 2px solid #9E9E9E
    border-radius: 2px
    cursor: pointer
    transition: all 250ms cubic-bezier(.4,.0,.23,1)

  input[type='checkbox']:checked + .check-label > .check-icon
    border: calc(var(--dimension) * 0.5) solid color.$ci
    animation: shrink-bounce 200ms cubic-bezier(.4,.0,.23,1)

  input[type='checkbox']:checked + .check-label > .check-icon:before
    content: ""
    position: absolute
    top: .5rem
    left: .5rem
    border-right: calc(var(--dimension) * 0.20) solid transparent
    border-bottom: calc(var(--dimension) * 0.20) solid transparent
    transform: rotate(45deg)
    transform-origin: 0% 100%
    animation: checkbox-check 125ms 250ms cubic-bezier(.4,.0,.23,1) forwards

  @keyframes shrink-bounce
    0%
      transform: scale(1)
    33%
      transform: scale(.85)
    100%
      transform: scale(1)

  @keyframes checkbox-check
    0%
      width: 0
      height: 0
      border-color: color.$white
      transform: translate3d(0,0,0) rotate(45deg)
    33%
      width: calc(var(--dimension) * 0.5)
      height: 0
      transform: translate3d(calc(var(--dimension) * -0.25), calc(var(--dimension) * -0.25), 0) rotate(45deg)
    100%
      width: calc(var(--dimension) * 0.4)
      height: calc(var(--dimension) * 0.7)
      border-color: color.$white
      transform: translate3d(calc(var(--dimension) * -0.25), calc(var(--dimension) * -0.5),0) rotate(45deg)
</style>
