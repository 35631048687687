import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import AddressDataKeys from '@services/address_data_keys.json'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import FetchingStatsDataOptionService from '@services/fetch_stats_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'shipping_methods',
  attributes: [
    'id',
    'name',
    'enabled',
    'rate_type',
    'rate_rule',
    'is_default',
    'shipping_categories',
    'gateway_type',
    'shipment_type_detail',
    'description'
  ],
  editableAttributes: [
    'name',
    'enabled',
    'rate_type',
    'rate_rule',
    'shipping_category_ids',
    'gateway_type',
    'shipment_type_detail',
    'description'
  ]
}

export default class ShippingMethod extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static fetchGateways() {
    return axios.get(
      `${new this().apiBasePath({ withResourceType: false })}/logistic_gateways`
    )
  }

  static configGateway(gateway) {
    let requestBody = {
      data: {
        type: 'gateway_config',
        attributes: {
          provider: gateway.provider
        }
      }
    }

    gateway.required_fields.forEach(
      (field) => (requestBody.data.attributes[field] = gateway[field])
    )

    return axios.put(
      `${new this().apiBasePath({
        withResourceType: false
      })}/logistic_gateways/${gateway.provider}`,
      requestBody
    )
  }

  static removeGatewayConfigurations(gateway) {
    return axios.delete(
      `${new this().apiBasePath({
        withResourceType: false
      })}/logistic_gateways/${gateway.provider}`
    )
  }

  toggle() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle`)
  }

  static fetchRateTypes() {
    return axios.get(`${new this().apiBasePath()}/rate_types`)
  }

  // extra methods or helpers here...
  static rateRuleTemplate(rateType) {
    switch (rateType) {
      case 'flat':
        return {
          price: ''
        }
      case 'flat_per_item':
        return {
          price: ''
        }
    }
  }

  isCvsLogistic() {
    return AddressDataKeys['cvs_data_keys'].includes(
      this.shipment_type_detail.shipping_type
    )
  }

  isStoreLogistic() {
    return this.shipment_type_detail.shipping_type === 'store'
  }

  isHomeLogistic() {
    return !this.isStoreLogistic() && !this.isCvsLogistic()
  }

  isOverseaLogistic() {
    return this.shipment_type_detail.shipping_type === 'oversea'
  }

  static fetchStats(options = {}) {
    const queryString = FetchingStatsDataOptionService.call(options)

    return axios.get(`${new this().apiBasePath()}/stats?${queryString}`)
  }

  fetchPartners(options = {}) {
    const queryString = FetchingDataOptionsService.call(options)

    return axios.get(`${this.apiBasePath()}/${this.id}/partners?${queryString}`)
  }
}
