import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_match_answers',
  attributes: [
    'id',
    'match_view_id',
    'match_product_id',
    'answer',
    'share_id',
    'created_at',
    'updated_at'
  ],
  editableAttributes: []
}

export default class PromoterMatchAnswer extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  // extra methods or helpers here...
}
