<template lang="pug">
.k-input(
  :is="component"
  :value="value"
  :placeholder="placeholder"
  :type="type"
  :size="size"
  :icon="icon"
  :icon-pack="iconPack"
  :icon-right="iconRight"
  :expanded="expanded"
  :disabled="disabled"
  :readonly="readonly"
  :max="max"
  :min="min"
  :options="options"
  @input="inputHandler"
)
  template(#label)
    slot(name="label")
  slot
</template>

<script setup>
import { ref, computed } from 'vue'
import Kolcenter from './k_input/kolcenter.vue'
import isBInput from './k_input/is-b-input.vue'

const STYLE_TYPE_MAP = {
  'is-b-input': isBInput,
  kolcenter: Kolcenter
}

const props = defineProps({
  styleType: {
    type: String,
    default() {
      return 'is-b-input'
    }
  },
  value: { type: [String, Number], required: false },
  type: { type: String, required: false },
  size: { type: String, required: false },
  placeholder: { type: String, required: false },
  expanded: { type: Boolean, required: false },
  iconPack: {
    type: String,
    required: false,
    validator(value) {
      return ['mdi', 'fa', 'fas', 'far', 'fad'].includes(value)
    }
  },
  icon: { type: String, required: false },
  iconRight: { type: String, required: false },
  disabled: { type: Boolean, required: false },
  readonly: { type: Boolean, required: false },
  max: { type: Number, required: false },
  min: { type: Number, required: false },
  options: { type: Object }
})

const emit = defineEmits(['input'])

const component = computed(() => {
  return STYLE_TYPE_MAP[props.styleType]
})

function inputHandler(value) {
  emit('input', value)
}
</script>
