import ResourceModelBase from 'odd-resource_model'
import moment from 'moment'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_share_levels',
  attributes: [
    'id',
    'share_id',
    'level_id',
    'is_current',
    'started_at',
    'ended_at',
    'operator_id',
    'note',
    'created_at',
    'updated_at'
  ],
  editableAttributes: [
    'level_id',
    'started_at',
    'ended_at',
    'note',
    'update_mode'
  ],
  timeAttributes: ['started_at', 'ended_at']
}

export default class PromoterShareLevel extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  timeAttributes() {
    return OPTIONS.timeAttributes
  }

  timeDisplay({ format = 'date' }) {
    const range = (() => {
      switch (format) {
        case 'date':
          return `${moment
            .unix(this.started_at)
            .format('YYYY/MM/DD')} ~ ${moment
            .unix(this.ended_at)
            .format('YYYY/MM/DD')}`
        case 'time':
          return `${moment
            .unix(this.started_at)
            .format('YYYY/MM/DD HH:mm:ss')} ~ ${moment
            .unix(this.ended_at)
            .format('YYYY/MM/DD HH:mm:ss')}`
      }
    })()

    const remainingDay = moment.unix(this.ended_at).diff(moment(), 'days')

    return {
      range,
      remainingDay
    }
  }

  static progressDisplay(salesTotal, goalSalesTotal) {
    const money = useMoney()

    const progressValue = round((salesTotal / goalSalesTotal) * 100, 1)

    const progressText = (() => {
      const nominator = money.toMoney(salesTotal).format()
      const denominator = money.toMoney(goalSalesTotal).format()
      return `${progressValue}% (${nominator} / ${denominator})`
    })()

    const progressType = (() => {
      if (progressValue < 25) {
        return 'is-danger'
      } else if (progressValue >= 25 && progressValue < 50) {
        return 'is-warning'
      } else if (progressValue >= 50 && progressValue < 75) {
        return 'is-info'
      } else if (progressValue >= 75) {
        return 'is-success'
      }
    })()

    return { progressValue, progressText, progressType }
  }
}
