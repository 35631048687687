import ResourceModelBase from 'odd-resource_model'
import axios from 'axios'
import moment from 'moment'
import FetchingStatsDataOptionsService from '../services/fetch_stats_data_options_service'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_match_products',
  attributes: [
    'id',
    'product_id',
    'interesteds_count',
    'uninteresteds_count',
    'super_likes_count',
    'is_enabled',
    'is_available',
    'position',
    'started_at',
    'ended_at',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['product_id', 'started_at', 'ended_at']
}

export default class PromoterMatchProduct extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static toggleEnabled({ productId }) {
    return axios.put(
      `${new this().apiBasePath()}/toggle_enabled?product_id=${productId}`
    )
  }

  static fetchStats(options = {}) {
    let queryString = FetchingStatsDataOptionsService.call(options)

    return axios.get(`${new this().apiBasePath()}/stats?${queryString}`)
  }

  displayPeriod(options = {}) {
    const defaults = {
      format: 'YYYY MMMDD HH:mm:ss',
      multipleLine: false
    }

    options = Object.assign({}, defaults, options)

    if (this.started_at && this.ended_at) {
      if (options.multipleLine)
        return `${moment
          .unix(this.started_at)
          .format(options.format)}<br>${moment
          .unix(this.ended_at)
          .format(options.format)}`

      return `${moment.unix(this.started_at).format(options.format)} ~ ${moment
        .unix(this.ended_at)
        .format(options.format)}`
    } else {
      return '∞'
    }
  }
}
