<template lang="pug">
//- "is-boxed" is a default type defined by Buefy

b-tabs.k-tabs-is-boxed(
  type="is-boxed"
  :value="value"
  :expanded="expanded"
  :size="size"
  @input="inputHandler"
)
  slot
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useInstance } from 'skid-composables'

const props = defineProps({
  value: { type: [Number, String], required: false },
  expanded: { type: Boolean, required: false },
  size: {
    type: String,
    requird: false,
    validator: (value) => ['is-small', 'is-medium'].includes(value)
  }
})

const emit = defineEmits(['input'])

const inputHandler = (value) => {
  emit('input', value)
}

// onMounted(() => {})
</script>
