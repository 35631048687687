import * as types from '../mutation-types'

export const fetchCampaignMainGroups = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchCampaignMainGroups')

  return new Promise((resolve, reject) => {
    model
      .fetchCampaignMainGroups(options)
      .then((response) => {
        dispatch(
          'promoterCampaignMainGroups/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        commit(types.API_REQUEST_SUCCESS, 'fetchCampaignMainGroups')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchCampaignMainGroups,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const saveCampaignMainGroup = (
  { dispatch, commit },
  { model, campaignMainGroup }
) => {
  commit(types.API_REQUEST_START, 'saveCampaignMainGroup')

  return new Promise((resolve, reject) => {
    model
      .saveCampaignMainGroup(campaignMainGroup)
      .then((response) => {
        dispatch(
          'promoterCampaignMainGroups/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )
        commit(types.API_REQUEST_SUCCESS, 'saveCampaignMainGroup')

        resolve(response)
      })
      .catch((errors) => {
        campaignMainGroup.errors.record(errors)
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: saveCampaignMainGroup,
            ref: {
              dispatch,
              commit
            },
            params: { model, campaignMainGroup }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const destroyCampaignMainGroup = (
  { dispatch, commit },
  { model, campaignMainGroup }
) => {
  commit(types.API_REQUEST_START, 'destroyCampaignMainGroup')

  return new Promise((resolve, reject) => {
    model
      .destroyCampaignMainGroup(campaignMainGroup)
      .then((response) => {
        commit(types.API_REQUEST_SUCCESS, 'destroyCampaignMainGroup')

        dispatch(
          'promoterCampaignMainGroups/receiveDestroyResourceFromRelationships',
          campaignMainGroup.id,
          {
            root: true
          }
        )

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: destroyCampaignMainGroup,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              id
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
