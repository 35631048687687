import PromoterEventVariantShip from '../../resource_models/promoter_event_variant_ship'

export const isLoading = (state) => {
  return state.isCallingAPI
}

export const all = (state) => {
  return state.result.map(
    (id) => new PromoterEventVariantShip(state.entities[id])
  )
}

export const find = (state) => (id) => {
  return new PromoterEventVariantShip(state.entities[id])
}

export const findBy = (state) => (attribues) => {
  return (
    all(state).find((record) => {
      return Object.keys(attribues).every((key) => {
        return record[key] === attribues[key]
      })
    }) || new PromoterEventVariantShip(attribues)
  )
}

export const meta = (state) => {
  return state.meta
}

export const errors = (state) => {
  return state.errors
}
