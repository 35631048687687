import * as types from '../mutation-types'

export const fetchPromoterMatchAnswerShares = (
  { dispatch, commit },
  { model, options = {} }
) => {
  commit(types.API_REQUEST_START, 'fetchPromoterMatchAnswerShares')

  return new Promise((resolve, reject) => {
    model
      .fetchPromoterMatchAnswerShares(options)
      .then((response) => {
        dispatch('promoters/receiveResourcesFromRelationships', response, {
          root: true
        })

        dispatch(
          'promoterMembers/receiveResourcesFromRelationships',
          response,
          {
            root: true
          }
        )

        dispatch('promoterShares/receiveResourcesFromRelationships', response, {
          root: true
        })

        commit(types.API_REQUEST_SUCCESS, 'fetchPromoterMatchAnswerShares')

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPromoterMatchAnswerShares,
            ref: {
              dispatch,
              commit
            },
            params: {
              model,
              options
            }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}

export const fetchPromoterMatchAnsweredShareStats = (
  { dispatch, commit },
  { model, statsKey, options }
) => {
  commit(types.API_REQUEST_START, 'fetchPromoterMatchAnsweredShareStats')
  return new Promise((resolve, reject) => {
    model
      .fetchPromoterMatchAnsweredShareStats(options)
      .then((response) => {
        commit(types.GET_STATS_SUCCESS, {
          statsKey,
          response
        })

        resolve(response)
      })
      .catch((errors) => {
        commit(types.API_REQUEST_FAIL, errors)
        dispatch(
          'errorMessageHandler',
          {
            errors,
            retryAction: fetchPromoterMatchAnsweredShareStats,
            ref: {
              dispatch,
              commit
            },
            params: { statsKey, options }
          },
          {
            root: true
          }
        )

        reject(errors)
      })
  })
}
