import axios from 'axios'
import ResourceModelBase from 'odd-resource_model'
import FetchingDataOptionsService from 'odd-fetching_data_options_service'
import ExtraQueryStringParseService from '../services/extra_query_string_parse_service.js'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'product_categories',
  attributes: [
    'id',
    'name',
    'description',
    'parent',
    'parent_id',
    'sub_categories',
    'products',
    'products_count',
    'created_at',
    'updated_at',
    'position',
    'name_with_parents',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'slug',
    'top_product_ids',
    'is_age_restricted',
    'is_available',
    'cover',
    'mobile_cover'
  ],
  editableAttributes: [
    'name',
    'description',
    'parent_id',
    'meta_title',
    'meta_description',
    'meta_keywords',
    'slug',
    'top_product_ids',
    'uploaded_banner_ids',
    'is_age_restricted',
    'is_available'
  ]
}

export default class ProductCategory extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  fetchProducts(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/products?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  selectGoods(options = {}) {
    return axios.get(
      `${this.apiBasePath()}/${
        this.id
      }/select_goods?${FetchingDataOptionsService.call(
        options
      )}${ExtraQueryStringParseService.call(options)}`
    )
  }

  saveBanner(formData) {
    return axios.post(`${this.apiBasePath()}/${this.id}/banners`, formData)
  }

  saveCover(formData) {
    return axios.put(`${this.apiBasePath()}/${this.id}/cover`, formData)
  }

  bulkAssignProducts(productIds) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_assign`, {
      product_ids: productIds
    })
  }

  bulkMoveProducts(data) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_move`, {
      target_category_id: data.targetCategory.id,
      product_ids: data.productIds
    })
  }

  bulkRemoveProducts(productIds) {
    return axios.put(`${this.apiBasePath()}/${this.id}/products/bulk_remove`, {
      product_ids: productIds
    })
  }

  removeProduct(product) {
    return axios.put(
      `${this.apiBasePath()}/${this.id}/products/${product.id}/remove`
    )
  }

  toggleAvailability() {
    return axios.put(`${this.apiBasePath()}/${this.id}/toggle_availability`)
  }
  // extra methods or helpers here...

  hasSubCategories() {
    return this.sub_categories.length > 0
  }

  updatePosition(data, options = {}) {
    return axios.put(
      `${this.apiBasePath()}/${
        this.id
      }/update_position?${FetchingDataOptionsService.call(options)}`,
      {
        target_instance_id: data.targetInstanceId
      }
    )
  }
}
